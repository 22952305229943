import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { Avatar } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import ShiflowIcon from '../../assets/images/shiflow.png';
import AudioBubble from '../audio-bubble';
import './index.scss';

const enum msgType {
  TEXT = 'text',
  IMAGE = 'image',
  VOICE = 'voice',
}

const enum roleType {
  ME = 'me',
  GPT = 'gpt'
}

interface IMessage {
  content?: string
  type?: string
  duration?: number
  role?: string
  costSeconds?: number
  tokens?: number
}

// const wsUrl = 'ws://123.56.214.189:8966/ws/shiflow/admin/stream/chats'
const wsUrl = 'wss://open.shiflow.com/ws/shiflow/v1/stream/chats'

function ChatBase({ store, title = '', config = {} }: any) {
  const [ws, setWs] = useState<WebSocket | null>(null)
  const [message, setMessage] = useState<string>('')
  const [uncompletedMessage, setUncompletedMessage] = useState<IMessage>({})
  const [messages, setMessages] = useState<IMessage[]>([])
  const [lastTime, setLastTime] = useState<number>(0)
  const [isShowScrollButton, setIsShowScrollButton] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [copyIndex, setCopyIndex] = useState(-1)
  const messagesEndRef = useRef(null)

  config = {
    app_id: config.app_id,
    name: config.name || 'GPT',
    icon: config.icon || ShiflowIcon,
    system_prompt: config.system_prompt || '',
    chat_model: config.chat_model || '',
    image_model: config.image_model || '',
    voice_model: config.voice_model || '',
  }

  useEffect(() => {
    initSocket()

    return () => {
      ws?.close()
    };
  }, [])

  useEffect(() => {
    if (messagesEndRef?.current) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setIsShowScrollButton(false)
        } else if (!uncompletedMessage.content) {
          setIsShowScrollButton(true)
        }
      }, {
        root: null,
        threshold: 1
      });

      observer.observe(messagesEndRef.current);
    }
  }, [messagesEndRef.current])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  useEffect(() => {
    const now = Date.now()
    if (now - lastTime <= 1000) return
    setLastTime(now)
    scrollToBottom()
  }, [uncompletedMessage])

  const initSocket = (): Promise<WebSocket> => {
    return new Promise(resolve => {
      const webSocket = new WebSocket(wsUrl)

      webSocket.onopen = () => {
        console.log('WebSocket 连接已打开')
        resolve(webSocket)
      }

      webSocket.onmessage = (event) => {
        const { data, is_end, msg_type } = JSON.parse(event.data)
        if (is_end) {
          let completedMessage = {}
          setUncompletedMessage(uncompletedMessage => {
            completedMessage = {
              content: (uncompletedMessage?.content ?? '') + data||'',
              type: uncompletedMessage?.type ?? msg_type,
              role: roleType.GPT,
            }
            return {}
          })
          setIsPending(false)
          setMessages(messages => [...messages, completedMessage])
        } else {
          setUncompletedMessage(uncompletedMessage => {
            return {
              content: (uncompletedMessage.content ?? '') + data || '',
              type: uncompletedMessage.type ?? msg_type,
              role: roleType.GPT,
            }
          })
        }
        console.log('收到消息:', JSON.parse(event.data))
      }

      webSocket.onerror = (error) => {
        console.log('WebSocket 出错:', error)
      }

      webSocket.onclose = function(event) {
        console.log("WebSocket 连接已关闭：", event);
      }

      setWs(webSocket)
    })
  }

  const scrollToBottom = () => {
    if (messagesEndRef?.current) {
      elementScrollIntoView(messagesEndRef.current, { block: 'end', behavior: 'smooth' })
    }
  };

  const onInput = (event: any) => {
    const value = event?.target?.value ?? ''
    setMessage(value)
  }

  const onKeyDown = (event: any) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      sendMessage()
    }
  };

  const sendMessage = async () => {
    let readyWebsocket = ws
    if (ws?.readyState === WebSocket.CLOSED) {
      console.log('Websocket 断线重连')
      readyWebsocket = await initSocket()
    }

    if (message.trim()) {
      const curMessages = [...messages, {
        content: message,
        type: msgType.TEXT,
        role: roleType.ME,
      }]
      setMessages(curMessages)
      setMessage('')

      let msg_type = 'text'
      if (config.image_model === 'dalle') msg_type = 'image'
      // else if (config.voice_model === 'tts') msg_type = 'voice'
      else if (config.voice_model === 'elevenlabs') msg_type = 'voice'

      const messageData = {
        data: message,
        msg_type: "gpt3.5",
        token: "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTM1MDM4MTAsImV4cCI6MTcxNDEwODYxMCwiYXVkIjoiaHR0cDovL2FwaS5zaGlmbG93LmNvbS9zaGlmbG93L3YxL29hdXRoL2p3dCIsInN1YiI6MTM0NTUsImNvbXBhbnkiOiJ5dW5rZSJ9.Ywp4-xn-MWGX0jG9NTywTuF12xP68mN9OafqKnrTMSI",
        window_id: 1464
        // msg_type,
        // data: {
        //   question: message,
        //   token: store.token,
        //   app_id: config.app_id,
        //   system_prompt: config.system_prompt,
        //   chat_model: config.chat_model,
        //   image_model: config.image_model,
        //   voice_model: config.voice_model,
        // },
      }

      console.log('发送消息', messageData)
      readyWebsocket?.send(JSON.stringify(messageData))
      setIsPending(true)
    }
  };

  const onHeightChange = () => {
    scrollToBottom()
  }

  const onScrollToBottom = () => {
    scrollToBottom()
  }

  const onCopy = (message: IMessage, index: number) => {
    // navigator.clipboard?.writeText?.(message?.content ?? '')

    try {
      const textarea = document.createElement('textarea')
      textarea.value = message?.content ?? ''
      textarea.style.position = 'fixed'
      textarea.style.opacity = '0'
      document.body.appendChild(textarea)
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea)
      setCopyIndex(index)
    } catch {}

    setTimeout(() => setCopyIndex(-1), 2000)
  }

  const renderMessage = (message: IMessage) => {
    switch (message.type) {
      case msgType.IMAGE:
        return <img className="message-image" src={message.content} alt="" />
      case msgType.VOICE:
        return <AudioBubble audioSrc={message.content as string} duration={message.duration} />
      default:
        return <p className="message">{message.content}</p>
    }
  }

  return (
    <div className="wrapper-chat-base">
      <h1 className="chat-title">{title}</h1>
        <div className="chat-list">
          {
            messages?.map((message, index) => <div className="chat-item" key={index}>
              {
                message.role === roleType.ME
                  ? <Avatar classes={{ root: 'avatar' }}>王</Avatar>
                  : <Avatar classes={{ root: 'avatar' }} src={config.icon} />
              }
            <div className="wrapper-message">
              <div className="nickname">{message.role === roleType.ME ? '我' : config.name}</div>
                {renderMessage(message)}
              </div>
            </div>)
          }
          {
            (isPending || uncompletedMessage.content) && <div className="chat-item">
              <Avatar classes={{ root: 'avatar' }} src={config.icon} />
              <div className="wrapper-message">
              <div className="nickname">{config.name}</div>
              <p className="message">
                {uncompletedMessage.content}<span className={classNames('input-cursor', { pending: !uncompletedMessage.content })} />
              </p>
              </div>
            </div>
          }
          <div ref={messagesEndRef} />
        </div>
      <div className="wrapper-input">
        {(isShowScrollButton && !uncompletedMessage.content) && <KeyboardDoubleArrowDownIcon className="button-reach-bottom" onClick={onScrollToBottom} />}
          <TextareaAutosize
            minRows={1}
            placeholder="请输入你的问题"
            className="message-input"
            value={message}
            onInput={onInput}
            onKeyDown={onKeyDown}
            onHeightChange={onHeightChange}
          />
          <div
            className={classNames('message-button', { active: message.length > 0 })}
            onClick={sendMessage}
          >
            <ArrowUpwardIcon className="icon-send" />
          </div>
        </div>
      </div>
  );
}

export default ChatBase;
