import ChatBase from '../components/chat-base';
import { useLayoutEffect } from 'react';

const DemoChat = ({ store }: any) => {
  useLayoutEffect(() => {
    store.setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MTM1MDM4MTAsImV4cCI6MTcxNDEwODYxMCwiYXVkIjoiaHR0cDovL2FwaS5zaGlmbG93LmNvbS9zaGlmbG93L3YxL29hdXRoL2p3dCIsInN1YiI6MTM0NTUsImNvbXBhbnkiOiJ5dW5rZSJ9.Ywp4-xn-MWGX0jG9NTywTuF12xP68mN9OafqKnrTMSI')
  }, [])
  return (
    <div className="bg-[#1f2028] flex-1">
      <ChatBase
        store={store}
        title="瑞腾云科"
        config={{
          app_id: 96,
          name: '云科AI',
          chat_model: 'gpt3.5'
        }}
      />
    </div>
  );
};

export default DemoChat;
