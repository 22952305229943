import { Button } from '@mui/material';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import './index.scss';

interface IAudioBubble {
  audioSrc: string
  duration?: number
}

const AudioBubble = ({ audioSrc, duration = 1 }: IAudioBubble) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef?.current) {
      (audioRef.current as any).play();
      setIsPlaying(true);
      console.log('play')
    }
  }, [audioRef.current])

  const togglePlay = () => {
    if (audioRef?.current) {
      if (isPlaying) {
        (audioRef.current as any).pause();
      } else {
        (audioRef.current as any).play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <Button
      variant="contained"
      className={classNames('audio-bubble', { playing: isPlaying })}
      onClick={togglePlay}
    >
      <audio ref={audioRef} onEnded={() => setIsPlaying(false)}>
        <source src={audioSrc} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <div className="wrapper-wave">
        {new Array(7).fill(0).map((_, index) => (
          <div key={index} className="wave" />
        ))}
      </div>
      <div className="duration">{duration}"</div>
    </Button>
  );
};

export default AudioBubble;