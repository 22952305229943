import Cookies from "js-cookie";
import { action, makeAutoObservable, observable } from "mobx";

class Store {
  constructor() {
    makeAutoObservable(this);
    this.token = Cookies.get('token') ?? '';
    // this.token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDU0MjA1NTczNzQsInN1YiI6IjIifQ.JoWvbtzuAoz7Hb2g_kCYVBsvK2RdHcZcD5gvczfqGUw';
  }

  @observable
  token = '';
    
  @action
  setToken(token: string) {
    this.token = token;
    if (token) Cookies.set('token', token);
  }

  @observable
  showLogin = false;

  @action
  setShowLogin(showLogin: boolean) {
    this.showLogin = showLogin;
  }
}

export default new Store();
