import { Provider, inject, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.scss';
import Store from './stores/index';
import DemoChat from './pages/demo-chat';

const ChatDemoPage = inject('store')(observer(DemoChat));

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!Store.token && pathname !== '/') {
      Store.setShowLogin(true);
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className="App">
      <Provider store={Store}>
        <Routes>
          <Route path="/" element={<ChatDemoPage />} />
        </Routes>
      </Provider>
    </div>
  );
}

export default observer(App);
